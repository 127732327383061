<template lang="pug">
  div.d-flex.flex-column.wrap-module-downgrade-plan
    div.container-downgrade-plan.py16
      p.fz14.mb18 チームプランをベーシックプランにダウングレードすると以下の機能がご利用いただけなくなります。
      ul.mb18
        li.fz14 チーム管理機能
        li.fz14 Zoomアカウントの連携&自動会議URLの発行
        li.fz14 Microsoft Teamsアカウントの連携&自動会議URLの発行

      p.fz14.mb8 これまでチームメンバーに追加したユーザーも上記の機能の利用が継続できなくなります。
      //- v-btn.next-button(@click="next()" width="100%"
        color="#FF2020" dark :disabled="!selectedPlanId").mb20 ダウングレードする
    ItemButtomBtn(label="ダウングレードする"
      :active="selectedPlanId ? true : false"
      :loading="processing"
      activeColor="#ff5252"
      @onBtn="next")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-downgrade-plan {
  .container-downgrade-plan {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
  }

  .next-button.v-btn--disabled {
    background-color: #ff2020 !important;
    opacity: 0.5;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import { deleteSubscription } from '@/services/functions'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const planSettingsStore = createNamespacedHelpers('planSettings')
const teamStore = createNamespacedHelpers('team')
import ItemButtomBtn from '@/components/item/ItemButtomBtn'

export default {
  components: {
    ItemButtomBtn
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...planSettingsStore.mapState(['planSettings'])
  },
  data() {
    return {
      selectedPlanId: null,
      processing: false
    }
  },
  async created() {
    // auto redirect to select-plan page if planId is not set
    if (!this.planSettings.planId) {
      this.$router.replace('/select-plan')
      return
    }

    this.selectedPlanId = this.planSettings.planId
  },
  methods: {
    ...planSettingsStore.mapMutations(['updatePlanSettings']),
    ...teamStore.mapActions(['loadTeamByUid']),
    async next() {
      this.processing = true
      try {
        await deleteSubscription()
        await this.loadTeamByUid(this.uid)
        this.$router.push('/account')
      } catch (error) {
        console.error(error)
      }
      this.processing = false
    }
  }
}
</script>
